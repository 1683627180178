<template>
  <v-dialog
     v-model="showing"
     width="100%"
     max-width="600px"
     @keydown.enter="save()"
  >
    <v-card>
      <v-card-title class="pb-0">
        <span class="headline">Filter</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
           v-model="internalFilters.search"
           append-icon="search"
           label="Search"
           single-line
           hide-details
           clearable
           class="mb-4"
        ></v-text-field>
        <business-autocomplete
           v-if="businessAutocomelete"
           v-model="internalFilters.business_uuid"
           :return-object="false"
        ></business-autocomplete>
        <v-select
           label="Compensation"
           v-model="internalFilters.commission_type"
           :items="commissionTypes"
           :return-object="false"
           clearable
        ></v-select>
        <follower-select
           v-model="internalFilters.min_followers"
        ></follower-select>
        <!--        <follower-select-->
        <!--          v-model="internalFilters.max_followers"-->
        <!--          label="Maximum følger krav"-->
        <!--        ></follower-select>-->
        <channel-category-select
           v-model="internalFilters.category_ids"
           :return-object="false"
        ></channel-category-select>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel()">
          Undo
        </v-btn>
        <v-btn color="primary" @click="save()">
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import FollowerSelect from "../../selects/FollowerSelect";
import ChannelCategorySelect from "../../selects/ChannelCategorySelect";
import BusinessAutocomplete from "../../autocompletes/BusinessAutocomplete";

export default {
  components: { ChannelCategorySelect, FollowerSelect, BusinessAutocomplete },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    filters: {
      required: true,
      type: Object
    },
    businessAutocomelete: {
      required: false,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    internalFilters: null,
    commissionTypes: [
      {text: 'Compensation per click', value: 'click'},
      {text: 'Compensation per per sale', value: 'conversion'},
      {text: 'Compensation per lead', value: 'lead'}
    ]
  }),
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.internalFilters = this.cloneDeep(newVal);
      }
    },
    showing() {
      this.internalFilters = this.cloneDeep(this.filters);
    }
  },
  methods: {
    save() {
      const params = ["min_cost_per_action_percent",  "business_uuid", "search", "min_cost_per_action_fixed"]
      if (
         "category_ids" in this.internalFilters &&
         this.internalFilters["category_ids"].length === 0
      ) {
        delete this.internalFilters["category_ids"];
      }
      params.forEach(param => {
        if (
           param in this.internalFilters &&
           this.internalFilters[param] === null
        ) {
          delete this.internalFilters[param];
        }
      });
      this.$emit("save", this.cloneDeep(this.internalFilters));
      this.showing = false;
    },
    cancel() {
      this.$emit("cancel");
      this.showing = false;
    }
  }
};
</script>
