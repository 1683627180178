<template>
  <div class="camapigns-table-wrapper">
    <v-data-table
      :headers="filteredHeaders"
      :items="getItems"
      :loading="loading"
      :hide-default-header="!$vuetify.breakpoint.smAndUp"
      hide-default-footer
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <!-- Not influencer -->
      <template v-slot:body="{ items }" v-if="!isInfluencer">
        <tbody>
          <tr v-for="item in items" :key="item.uuid">
            <td>
              <router-link
                :to="{ name: 'campaign', params: { id: item.uuid } }"
              >
                {{ item.name }}
              </router-link>
            </td>
            <td v-if="hasHeader('business')">
              <router-link
                :to="{ name: 'business', params: { id: item.business.uuid } }"
              >
                {{ item.business.display_name }}
              </router-link>
            </td>
            <td>
              <template>
                <div class="pa-1"></div>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-list-item-icon v-on="on">
                      <v-icon class="mt-1 menu-icon" v-if="item.hidden"
                        >fal fa-eye-slash</v-icon
                      >
                      <v-icon class="mt-1 menu-icon" v-else>fal fa-eye</v-icon>
                    </v-list-item-icon>
                  </template>
                  <span v-if="item.hidden">Privat</span>
                  <span v-else>Public</span>
                </v-tooltip>
              </template>
            </td>
            <td>
              <template
                v-if="
                  item.cost_per_action_percent !== null &&
                    item.cost_per_action_fixed !== null
                "
              >
                {{ item.cost_per_action_percent | local_numbers }}% + DKK
                {{ item.cost_per_action_fixed | local_numbers }}
              </template>
              <template v-else-if="item.cost_per_action_percent !== null">
                {{ item.cost_per_action_percent | local_numbers }}%
              </template>
              <template v-else-if="item.cost_per_action_fixed !== null">
                DKK {{ item.cost_per_action_fixed | local_numbers }}
              </template>
            </td>
            <td>
              {{ item.influencers.length | local_numbers }}
            </td>
            <td>
              <campaign-status-icon
                :value="item"
                show-text
              ></campaign-status-icon>
            </td>
            <td>{{ item.created_at | utc_to_local_datetime }}</td>
          </tr>
        </tbody>
      </template>
      <!-- Influencer -->
      <template v-slot:body="{ items }" v-else>
        <tbody v-if="$vuetify.breakpoint.smAndUp">
          <tr v-for="item in items" :key="item.uuid">
            <td>
              <campaign-status-icon
                :value="item"
                show-text
              ></campaign-status-icon>
            </td>
            <td>
              <router-link
                :to="{ name: 'campaign', params: { id: item.uuid } }"
              >
                {{ item.name }}
              </router-link>
            </td>
            <td>
              <template v-if="item.business">
                {{ item.business.display_name }}
              </template>
            </td>
            <td>
              <template
                v-if="
                  item.cost_per_action_percent !== null &&
                    item.cost_per_action_fixed !== null
                "
              >
                {{ item.cost_per_action_percent | local_numbers }}% + DKK
                {{ item.cost_per_action_fixed | local_numbers }}
              </template>
              <template v-else-if="item.cost_per_action_percent !== null">
                {{ item.cost_per_action_percent | local_numbers }}%
              </template>
              <template v-else-if="item.cost_per_action_fixed !== null">
                DKK {{ item.cost_per_action_fixed | local_numbers }}
              </template>
            </td>
            <td>{{ item.min_followers | local_numbers }}</td>
            <td>{{ item.created_at | utc_to_local_datetime }}</td>
            <td>{{ item.ending_at | utc_to_local_datetime }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr
            v-for="item in items"
            :key="item.uuid"
            @click="openCampaignCard(item)"
          >
            <td class="text-no-wrap">
              <campaign-status-icon
                :value="item"
                show-text
              ></campaign-status-icon>
            </td>
            <td>
              <span class="text-no-wrap">
                {{ item.name }}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-pagination
      v-model="currPage"
      class="my-2"
      :length="total"
      :total-visible="7"
      @input="handlePageChange"
    ></v-pagination>
    <v-dialog
      v-model="openCampaignDialog"
      max-width="550px"
      @click:outside="openCampaignCard(null)"
    >
      <campaign-list-card
        v-if="selectedCampaign"
        :campaign="selectedCampaign"
        class="w-100"
      >
        <template v-slot:footer>
          <v-btn
            color="primary"
            block
            :to="{ name: 'campaign', params: { id: selectedCampaign.uuid } }"
          >
            See campaign
          </v-btn>
        </template>
      </campaign-list-card>
    </v-dialog>
  </div>
</template>
<script>
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import local_numbers from "@/helpers/filters/local_numbers";
import CampaignStatusIcon from "../icons/CampaignStatusIcon";
import CampaignListCard from "../cards/CampaignListCard";

export default {
  components: { CampaignStatusIcon, CampaignListCard },
  filters: {
    utc_to_local_datetime,
    local_numbers
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    page: {
      type: Number
    },
    total: {
      type: Number
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    exclude: {
      type: Array,
      required: false,
      default: () => []
    },
    isInfluencer: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    headers: [],
    length: 12,
    currPage: 1,
    selectedCampaign: null,
    openCampaignDialog: false
  }),
  computed: {
    filteredHeaders() {
      let finalHeaders = [];
      this.headers.forEach(item => {
        if (!this.exclude.includes(item.value)) {
          finalHeaders.push(item);
        }
      });
      return finalHeaders;
    },
    isBusinessPage() {
      return this.$route.name === "business" ? true : false;
    }
  },
  methods: {
    handlePageChange(value) {
      this.$emit("changePage", value);
    },
    hasHeader(value) {
      return (
        this.filteredHeaders.filter(item => item.value === value).length > 0
      );
    },
    calcPageArray(page) {
      const index = this.length * (page - 1);
      this.getItems = this.items.slice(index, index + this.length);
    },
    setHeaderColumn() {
      if (!this.isInfluencer && this.isBusinessPage) {
        this.headers = [
          { text: "Campaign", value: "name", sortable: false },
          { text: "Visibility", value: "visability", sortable: false },
          { text: "Commission", value: "percent", sortable: false },
          { text: "Influencers", value: "influencers", sortable: false },
          { text: "Status", value: "status", sortable: false },
          { text: "Created at", value: "created_at", sortable: false }
        ];
      } else if (!this.isInfluencer) {
        this.headers = [
          { text: "Campaign", value: "name", sortable: false },
          { text: "Brand", value: "business", sortable: false },
          { text: "Visibility", value: "visability", sortable: false },
          { text: "Commission", value: "percent", sortable: false },
          { text: "Influencers", value: "influencers", sortable: false },
          { text: "Status", value: "status", sortable: false },
          { text: "Created at", value: "created_at", sortable: false }
        ];
      } else {
        this.headers = [
          { text: "Status", sortable: false },
          { text: "Campaign", sortable: false },
          { text: "Brand", sortable: false },
          { text: "Commission", sortable: false },
          { text: "Follower requirements", sortable: false },
          { text: "Start date", sortable: false },
          { text: "End date", sortable: false }
        ];
      }
    },
    openCampaignCard(campaign) {
      this.openCampaignDialog = true;
      this.selectedCampaign = campaign;
    },
    updateData() {
      this.setHeaderColumn();
      // calc page items
      this.calcPageArray(1);
    }
  },
  watch: {
    page(index) {
      this.calcPageArray(index);
      window.scrollTo(0, 0);
    },
    items() {
      this.currPage = this.page;
      this.updateData();
    }
  },
  created() {
    this.currPage = this.page;
    this.updateData();
  }
};
</script>
<style lang="scss" scoped>
.v-pagination__item {
  outline: 0 !important;
}
</style>
