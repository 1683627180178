<template>
  <v-select
    v-if="returnObject && !hasIcons"
    :items="categories"
    v-model="selected"
    :label="label"
    :multiple="multiple"
    :menu-props="{ bottom: true, offsetY: true }"
    persistent-hint
    return-object
    :disabled="disabled"
    :error-messages="errorMessages"
    item-text="display_name"
    data-intercom-target="select.category"
  >
  </v-select>
  <v-select
    v-else-if="!hasIcons && !returnObject"
    :items="categories"
    v-model="selected"
    :label="label"
    :menu-props="{ bottom: true, offsetY: true }"
    :multiple="multiple"
    persistent-hint
    :disabled="disabled"
    :error-messages="errorMessages"
    item-value="id"
    item-text="display_name"
    data-intercom-target="select.category"
  >
  </v-select>
  <v-select
    v-else-if="hasIcons && !returnObject"
    :items="categories"
    v-model="selected"
    :label="label"
    :multiple="multiple"
    :menu-props="{ bottom: true, offsetY: true }"
    persistent-hint
    :disabled="disabled"
    :error-messages="errorMessages"
    item-value="id"
    item-text="display_name"
    data-intercom-target="select.category"
  >
    <!-- Selected -->
    <template v-slot:selection="{ item }">
      <v-list-item-avatar>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="item.display_name"></v-list-item-title>
      </v-list-item-content>
    </template>
    <!--List item-->
    <template slot="item" slot-scope="prop">
      <v-list-item-avatar>
        <v-icon>{{ prop.item.icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="prop.item.display_name"></v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
  <v-select
    v-else-if="hasIcons && returnObject"
    :items="categories"
    v-model="selected"
    :label="label"
    :multiple="multiple"
    :menu-props="{ bottom: true, offsetY: true }"
    persistent-hint
    :disabled="disabled"
    :error-messages="errorMessages"
    item-value="id"
    item-text="display_name"
    data-intercom-target="select.category"
  >
    <!-- Selected -->
    <template v-slot:selection="{ item }">
      <v-list-item-avatar>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="item.display_name"></v-list-item-title>
      </v-list-item-content>
    </template>
    <!--List item-->
    <template slot="item" slot-scope="prop">
      <v-list-item-avatar>
        <v-icon>{{ prop.item.icon }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="prop.item.display_name"></v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      require: true
    },
    disabled: {
      require: false,
      type: Boolean
    },
    "error-messages": {
      require: false,
      type: [Array, String]
    },
    returnObject: {
      default: true,
      type: Boolean
    },
    multiple: {
      default: true,
      type: Boolean
    },
    hasIcons: {
      default: false,
      type: Boolean
    },
    label: {
      default: "Categories",
      type: String
    }
  },
  data: () => ({
    categories: []
  }),
  methods: {
    ...mapActions("core", ["getChannelCategories"])
  },
  computed: {
    selected: {
      get() {
        const data =
          this.value && this.value.length && typeof this.value !== "string"
            ? this.value.map(i => i.toString())
            : this.value;
        return data;
      },
      set(val) {
        if (this.hasIcons && this.returnObject) {
          const item = this.categories.find(
            item => Number(item.id) === Number(val)
          );
          this.$emit("input", item);
        } else {
          this.$emit("input", val);
        }
      }
    },
    icon() {
      const item = this.categories.find(
        item => Number(item.id) === Number(this.value.id)
      );
      if (this.returnObject && item) {
        return item.icon;
      } else {
        if (item) {
          return item.icon;
        }
      }
      return null;
    }
  },
  created() {
    this.getChannelCategories().then(response => {
      this.categories = response.data.channelCategories;
    });
  }
};
</script>
