<template>
  <v-autocomplete
    v-model="selected"
    :search-input.sync="search"
    :loading="loading"
    :items="filteredItems"
    label="Business"
    item-text="full_name"
    item-value="id"
    return-object
    :clearable="clearable"
    :error-messages="errorMessages"
    :disabled="disabled"
    :outlined="outlined"
  >
    <template slot="selection" slot-scope="data">
      <v-list v-if="$vuetify.breakpoint.smAndUp" style="max-width: 450px;">
        <v-list-item>
          <v-list-item-avatar v-if="avatar">
            <user-avatar :user="data.item.user"></user-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ data.item.display_name }}</v-list-item-title>
            <v-list-item-subtitle class="text--secondary">{{
              data.item.user.first_name + " " + data.item.user.last_name
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list-item-title
        v-else
        v-html="prop.item.display_name"
        style="max-width: 220px;"
      ></v-list-item-title>
    </template>

    <v-list slot="no-data">
      <v-list-item>
        <v-list-item-title>No result</v-list-item-title>
      </v-list-item>
    </v-list>

    <template slot="item" slot-scope="data">
      <v-list-item-avatar>
        <user-avatar :user="data.item.user"></user-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ data.item.display_name }}</v-list-item-title>
        <v-list-item-subtitle class="text--secondary">{{
          data.item.user.first_name + " " + data.item.user.last_name
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import date_to_age from "@/helpers/filters/date_to_age";
import UserAvatar from "../avatars/UserAvatar";
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  components: { UserAvatar },
  filters: { date_to_age },
  props: {
    value: {
      required: true,
    },
    avatar: {
      required: false,
      type: Boolean,
      default: true,
    },
    returnObject: {
      required: false,
      type: Boolean,
      default: true,
    },
    clearable: {
      required: false,
      type: Boolean,
      default: true,
    },
    errorMessages: {
      required: false,
      default: null,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    filters: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    outlined: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    internalValue: null,
    items: [],
    search: "",
    loading: false,
  }),
  computed: {
    selected: {
      get() {
        return this.internalValue;
      },
      set(val) {
        if (typeof val === "undefined") {
          val = null;
        }

        this.internalValue = val;

        if (this.returnObject) {
          this.$emit("input", val);
        } else if (typeof val === "object" && val !== null) {
          this.$emit("input", val.uuid);
        } else {
          this.$emit("input", val);
        }
      },
    },
    filteredItems() {
      let items = this.cloneDeep(this.items);

      // Check if selected exists
      if (this.internalValue !== null) {
        let exist = items.filter(
          (item) => item.uuid === this.internalValue.uuid
        );

        if (exist.length === 0) {
          items.push(this.internalValue);
        }
      }

      return items.map((item) => {
        item.full_name =
          item.user.first_name +
          " " +
          item.user.last_name +
          " " +
          item.display_name +
          " " +
          item.company;

        return item;
      });
    },
  },
  watch: {
    search: {
      handler: _.debounce(function() {
        this.getItems();
      }, 500),
    },
    disabled() {
      this.getItems();
    },
    filters() {
      this.getItems();
    },
  },
  methods: {
    ...mapActions("core", ["getBusinesses", "getBusiness"]),
    getItems() {
      if (this.disabled || this.loading) {
        return;
      }
      this.loading = true;
      let filters = this.cloneDeep(this.filters);

      const params = {
        ...filters,
        search: this.search,
        limit: 100,
        page: 1,
      };

      this.getBusinesses(params).then((businesses) => {
        this.items = businesses.response;

        this.loading = false;
      });
    },
  },
  created() {
    this.getItems();

    // Get object if already has value
    if (this.value !== null && typeof this.value !== "undefined") {
      let selectedId = this.value;

      if (typeof this.value === "object") {
        selectedId = this.value.uuid;
      }

      this.getBusiness({ business_uuid: selectedId }).then((business) => {
        this.internalValue = business;
      });
    }
  },
};
</script>

<style scoped></style>
