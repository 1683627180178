export default {
  methods: {
    setUrlSearchFilters(filters) {
      let finalFilters = {};

      for (let prop in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, prop)) {
          let value = filters[prop];

          if (Array.isArray(value)) {
            finalFilters[prop + "[]"] = value.map(e => {
              return e instanceof Object ? JSON.stringify(e) : e;
            });
          } else if (value instanceof Object) {
            finalFilters[prop] = JSON.stringify(value);
          } else {
            finalFilters[prop] = value;
          }
        }
      }

      this.$router
        .replace({ query: finalFilters })
        // Added dummy catch to remove log error
        .catch(() => {});
    },
    getUrlSearchFilters() {
      let query = {};

      for (let prop in this.$route.query) {
        let value = this.$route.query[prop];

        if (prop.indexOf("[]") !== -1) {
          let propName = prop.substr(0, prop.length - 2);

          if (!Object.prototype.hasOwnProperty.call(query, propName)) {
            if (value && !isNaN(value)) {
              value = parseInt(value);
            }

            if (!Array.isArray(value)) {
              try {
                value = [JSON.parse(value)];
              } catch (e) {
                value = [value];
              }
            } else {
              value = value.map(entry => {
                try {
                  return JSON.parse(entry);
                } catch (e) {
                  return entry;
                }
              });
            }

            query[propName] = value;
          }
        } else {
          if (value && !isNaN(value)) {
            value = parseInt(value);
          }

          try {
            value = JSON.parse(value);
          } catch (e) {
            // empty
          }

          query[prop] = value;
        }
      }

      return query;
    }
  }
};
