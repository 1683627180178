var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"camapigns-table-wrapper"},[_c('v-data-table',{attrs:{"headers":_vm.filteredHeaders,"items":_vm.getItems,"loading":_vm.loading,"hide-default-header":!_vm.$vuetify.breakpoint.smAndUp,"hide-default-footer":""},scopedSlots:_vm._u([(!_vm.isInfluencer)?{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.uuid},[_c('td',[_c('router-link',{attrs:{"to":{ name: 'campaign', params: { id: item.uuid } }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),(_vm.hasHeader('business'))?_c('td',[_c('router-link',{attrs:{"to":{ name: 'business', params: { id: item.business.uuid } }}},[_vm._v(" "+_vm._s(item.business.display_name)+" ")])],1):_vm._e(),_c('td',[[_c('div',{staticClass:"pa-1"}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-icon',_vm._g({},on),[(item.hidden)?_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-eye-slash")]):_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-eye")])],1)]}}],null,true)},[(item.hidden)?_c('span',[_vm._v("Privat")]):_c('span',[_vm._v("Public")])])]],2),_c('td',[(
                item.cost_per_action_percent !== null &&
                  item.cost_per_action_fixed !== null
              )?[_vm._v(" "+_vm._s(_vm._f("local_numbers")(item.cost_per_action_percent))+"% + DKK "+_vm._s(_vm._f("local_numbers")(item.cost_per_action_fixed))+" ")]:(item.cost_per_action_percent !== null)?[_vm._v(" "+_vm._s(_vm._f("local_numbers")(item.cost_per_action_percent))+"% ")]:(item.cost_per_action_fixed !== null)?[_vm._v(" DKK "+_vm._s(_vm._f("local_numbers")(item.cost_per_action_fixed))+" ")]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(_vm._f("local_numbers")(item.influencers.length))+" ")]),_c('td',[_c('campaign-status-icon',{attrs:{"value":item,"show-text":""}})],1),_c('td',[_vm._v(_vm._s(_vm._f("utc_to_local_datetime")(item.created_at)))])])}),0)]}}:{key:"body",fn:function(ref){
              var items = ref.items;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.uuid},[_c('td',[_c('campaign-status-icon',{attrs:{"value":item,"show-text":""}})],1),_c('td',[_c('router-link',{attrs:{"to":{ name: 'campaign', params: { id: item.uuid } }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('td',[(item.business)?[_vm._v(" "+_vm._s(item.business.display_name)+" ")]:_vm._e()],2),_c('td',[(
                item.cost_per_action_percent !== null &&
                  item.cost_per_action_fixed !== null
              )?[_vm._v(" "+_vm._s(_vm._f("local_numbers")(item.cost_per_action_percent))+"% + DKK "+_vm._s(_vm._f("local_numbers")(item.cost_per_action_fixed))+" ")]:(item.cost_per_action_percent !== null)?[_vm._v(" "+_vm._s(_vm._f("local_numbers")(item.cost_per_action_percent))+"% ")]:(item.cost_per_action_fixed !== null)?[_vm._v(" DKK "+_vm._s(_vm._f("local_numbers")(item.cost_per_action_fixed))+" ")]:_vm._e()],2),_c('td',[_vm._v(_vm._s(_vm._f("local_numbers")(item.min_followers)))]),_c('td',[_vm._v(_vm._s(_vm._f("utc_to_local_datetime")(item.created_at)))]),_c('td',[_vm._v(_vm._s(_vm._f("utc_to_local_datetime")(item.ending_at)))])])}),0):_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.uuid,on:{"click":function($event){return _vm.openCampaignCard(item)}}},[_c('td',{staticClass:"text-no-wrap"},[_c('campaign-status-icon',{attrs:{"value":item,"show-text":""}})],1),_c('td',[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.name)+" ")])])])}),0)]}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1),_c('v-pagination',{staticClass:"my-2",attrs:{"length":_vm.total,"total-visible":7},on:{"input":_vm.handlePageChange},model:{value:(_vm.currPage),callback:function ($$v) {_vm.currPage=$$v},expression:"currPage"}}),_c('v-dialog',{attrs:{"max-width":"550px"},on:{"click:outside":function($event){return _vm.openCampaignCard(null)}},model:{value:(_vm.openCampaignDialog),callback:function ($$v) {_vm.openCampaignDialog=$$v},expression:"openCampaignDialog"}},[(_vm.selectedCampaign)?_c('campaign-list-card',{staticClass:"w-100",attrs:{"campaign":_vm.selectedCampaign},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","block":"","to":{ name: 'campaign', params: { id: _vm.selectedCampaign.uuid } }}},[_vm._v(" See campaign ")])]},proxy:true}],null,false,1725212676)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }