<template>
  <div>
    <v-icon small :class="statusMeta.class" class="mr-2">fas fa-circle</v-icon>
    <span v-if="showText" v-text="statusMeta.text"></span>
  </div>
</template>
<script>
// imports

export default {
  props: {
    value: {
      required: true,
      type: Object
    },
    showText: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    statusMeta() {
      let meta = {
        text: "",
        class: ""
      };

      if (this.value.status.name === "not_started") {
        meta.text = "Afventer start";
        meta.class = "yellow--text";
      } else if (this.value.status.name === "ended") {
        meta.text = "Completed";
        meta.class = "error--text";
      } else {
        meta.text = "Active";
        meta.class = "success--text";
      }

      return meta;
    }
  }
};
</script>

<style scoped></style>
