<template>
  <v-select
    v-model="selected"
    :items="items"
    :label="label"
    clearable
  ></v-select>
</template>

<script>
export default {
  name: "FollowerSelect",
  props: {
    value: {
      required: true
    },
    label: {
      default: "Minimum followers"
    }
  },
  data: () => ({
    items: [0, 1000, 5000, 10000, 50000, 100000, 500000, 1000000]
  }),
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style scoped></style>
